* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: #444;
}

body, html, #root {
  height: 100%;
  overflow: hidden;
}
